a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0;
    background: 0 0
}

td {
    vertical-align: middle
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body,
button,
fieldset,
form,
html,
textarea {
    font: 100%/1.3 Montserrat,Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0
}

body {
    line-height: 1
}

iframe,
img,
object {
    border: 0 none
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

:focus {
    outline: 0
}

address,
blockquote,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul {
    margin: 1em 0;
    padding: 0
}

blockquote,
dd,
li {
    margin-left: 1em
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

ins {
    text-decoration: none
}

del {
    text-decoration: line-through
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

caption,
td,
th {
    font-weight: 400
}

form label {
    cursor: pointer
}

fieldset {
    border: none
}

input,
select,
textarea {
    font-family: inherit;
    font-size: 100%
}

a {
    cursor: pointer;
    text-decoration: none
}

blockquote:first-child,
dl:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
ol:first-child,
p:first-child,
pre:first-child,
ul:first-child {
    margin-top: 0
}

blockquote,
code,
img,
input,
pre,
table,
td,
textarea,
video {
    max-width: 100%
}

article,
aside,
code,
details,
div,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
pre,
samp,
section,
table,
td,
textarea,
th {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto
}

* {
    box-sizing: border-box
}