@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
	font-style: normal;
    font-display: block;
}

.icon-arrow-left-angle:before,
.icon-arrow-right-angle:before,
.icon-arrow_bot:before,
.icon-arrow_left:before,
.icon-arrow_right:before,
.icon-arrow_top:before,
.icon-calendar:before,
.icon-calendar2:before,
.icon-certif:before,
.icon-clock:before,
.icon-clock2:before,
.icon-close:before,
.icon-cochon:before,
.icon-mouse:before,
.icon-coeur:before,
.icon-conseils:before,
.icon-instagram:before,
.icon-doc:before,
.icon-double-left-chevron:before,
.icon-download:before,
.icon-enfants:before,
.icon-facebook-rounded:before,
.icon-linkedin-rounded:before,
.icon-facebook:before,
.icon-facebook2:before,
.icon-twitter-rounded:before,
.icon-favorite-none:before,
.icon-favorite:before,
.icon-femme:before,
.icon-handicap:before,
.icon-homme:before,
.icon-itineraire:before,
.icon-itineraire2:before,
.icon-itineraire3:before,
.icon-lens:before,
.icon-lensv2:before,
.icon-locate:before,
.icon-madein:before,
.icon-mail:before,
.icon-mapmonde:before,
.icon-marker:before,
.icon-marker_map:before,
.icon-membre:before,
.icon-parking:before,
.icon-phone:before,
.icon-placeholder:before,
.icon-search:before,
.icon-true:before,
.icon-twitter:before,
.icon-velo:before,
.icon-true_v2:before,
.icon-oreille:before,
.icon-durable:before,
.icon-favoris:before,
.icon-facebook-rounded2:before,
.icon-mail2:before,
.icon-arrow-bottom-angle:before,
.icon-phone2:before,
.icon-grid:before,
.icon-line:before,
.icon-linkedin:before,
.icon-local-double:before,
.icon-triangle-bottom:before,
.icon-visit:before,
.icon-fournisseur:before,
.icon-france:before,
.icon-premium:before,
.icon-pinterest:before,
.icon-youtube:before,
.icon-arrow-right2:before,
.icon-mobile:before,
.icon-instagram-rounded:before,
.icon-youtube-rounded:before,
.icon-money:before,
.icon-health:before,
.icon-map:before,
.icon-marque:before,
.icon-prix:before,
.icon-conseil2:before,
.icon-calendar3:before,
.icon-hearing-aid:before,
.icon-calendar4:before,
.icon-mutuelle:before,
.icon-paiement:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-arrow-left-angle:before { content: "\EA01" }
.icon-arrow-right-angle:before { content: "\EA02" }
.icon-arrow_bot:before { content: "\EA03" }
.icon-arrow_left:before { content: "\EA04" }
.icon-arrow_right:before { content: "\EA05" }
.icon-arrow_top:before { content: "\EA06" }
.icon-calendar:before { content: "\EA07" }
.icon-calendar2:before { content: "\EA08" }
.icon-certif:before { content: "\EA09" }
.icon-clock:before { content: "\EA0A" }
.icon-clock2:before { content: "\EA0B" }
.icon-close:before { content: "\EA0C" }
.icon-cochon:before { content: "\EA0D" }
.icon-mouse:before { content: "\EA0E" }
.icon-coeur:before { content: "\EA0F" }
.icon-conseils:before { content: "\EA10" }
.icon-instagram:before { content: "\EA11" }
.icon-doc:before { content: "\EA12" }
.icon-double-left-chevron:before { content: "\EA13" }
.icon-download:before { content: "\EA14" }
.icon-enfants:before { content: "\EA15" }
.icon-facebook-rounded:before { content: "\EA16" }
.icon-linkedin-rounded:before { content: "\EA17" }
.icon-facebook:before { content: "\EA18" }
.icon-facebook2:before { content: "\EA19" }
.icon-twitter-rounded:before { content: "\EA1A" }
.icon-favorite-none:before { content: "\EA1B" }
.icon-favorite:before { content: "\EA1C" }
.icon-femme:before { content: "\EA1D" }
.icon-handicap:before { content: "\EA1F" }
.icon-homme:before { content: "\EA20" }
.icon-itineraire:before { content: "\EA21" }
.icon-itineraire2:before { content: "\EA22" }
.icon-itineraire3:before { content: "\EA23" }
.icon-lens:before { content: "\EA24" }
.icon-lensv2:before { content: "\EA25" }
.icon-locate:before { content: "\EA26" }
.icon-madein:before { content: "\EA2A" }
.icon-mail:before { content: "\EA2B" }
.icon-mapmonde:before { content: "\EA2C" }
.icon-marker:before { content: "\EA2D" }
.icon-marker_map:before { content: "\EA2E" }
.icon-membre:before { content: "\EA2F" }
.icon-parking:before { content: "\EA33" }
.icon-phone:before { content: "\EA34" }
.icon-placeholder:before { content: "\EA35" }
.icon-search:before { content: "\EA36" }
.icon-true:before { content: "\EA39" }
.icon-twitter:before { content: "\EA3A" }
.icon-velo:before { content: "\EA3B" }
.icon-true_v2:before { content: "\EA3D" }
.icon-oreille:before { content: "\EA3E" }
.icon-durable:before { content: "\EA40" }
.icon-favoris:before { content: "\EA41" }
.icon-facebook-rounded2:before { content: "\EA42" }
.icon-mail2:before { content: "\EA43" }
.icon-arrow-bottom-angle:before { content: "\EA44" }
.icon-phone2:before { content: "\EA45" }
.icon-grid:before { content: "\EA46" }
.icon-line:before { content: "\EA47" }
.icon-linkedin:before { content: "\EA48" }
.icon-local-double:before { content: "\EA49" }
.icon-triangle-bottom:before { content: "\EA4A" }
.icon-visit:before { content: "\EA4B" }
.icon-fournisseur:before { content: "\EA58" }
.icon-france:before { content: "\EA59" }
.icon-premium:before { content: "\EA5A" }
.icon-pinterest:before { content: "\EA5B" }
.icon-youtube:before { content: "\EA5C" }
.icon-arrow-right2:before { content: "\EA5D" }
.icon-mobile:before { content: "\EA5E" }
.icon-instagram-rounded:before { content: "\EA5F" }
.icon-youtube-rounded:before { content: "\EA60" }
.icon-money:before { content: "\EA61" }
.icon-health:before { content: "\EA62" }
.icon-map:before { content: "\EA64" }
.icon-marque:before { content: "\EA65" }
.icon-prix:before { content: "\EA66" }
.icon-conseil2:before { content: "\EA67" }
.icon-calendar3:before { content: "\EA68" }
.icon-hearing-aid:before { content: "\EA69" }
.icon-calendar4:before { content: "\EA6A" }
.icon-mutuelle:before { content: "\EA6B" }
.icon-paiement:before { content: "\EA6C" }
