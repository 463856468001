body,
button,
fieldset,
form,
html,
textarea {
    font-family: Montserrat,Verdana,Arial,Helvetica,sans-serif
}

.sep {
    height: 1px;
    margin: 15px 0;
    background-color: #d4d8da
}

.clear,
.line,
.row,
.wrap {
    clear: both
}

.big {
    font-size: 120%
}

.mini {
    font-size: 80%
}

.text-center {
    text-align: center
}

.button1 {
    font-size: 12px;
    display: inline-block;
    width: 200px;
    padding: 15px 20px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    border-radius: 20px;
    background: #81a3bf
}

.button1:hover {
    color: #fff;
    border-radius: 10px
}

.button2 {
    font-family: Montserrat,sans-serif;
    font-size: 13px;
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 15px 20px;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    background: 0 0
}

.button2:hover {
    color: #fff;
    background: rgba(0,0,0,.7)
}

.button3,
a.button3 {
    font-family: Montserrat,sans-serif;
    font-size: 13px;
    display: inline-block;
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    vertical-align: middle;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #feca1c;
    border-radius: 20px;
    outline: 0;
    background: #feca1c
}

.button4 {
    font-size: 12px;
    display: inline-block;
    width: 200px;
    padding: 15px 20px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    border-radius: 50px;
    background: #b56259
}

.button4:hover {
    background: #784137
}

#formulaire-avis input.button5,
.button5 {
    font-size: 12px;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background: #020202
}

.button_blue {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 1.8rem 3.6rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #0ea6af;
    border: .1rem solid #0ea6af;
    border-radius: 3rem;
    transition: all .3s ease-in-out
}

.button_blue:focus,
.button_blue:hover {
    cursor: pointer;
    color: #0ea6af;
    background-color: #fff
}

.button_green {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 1.8rem 3.6rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #417f35;
    border: .1rem solid #417f35;
    border-radius: 3rem;
    transition: all .3s ease-in-out
}

.button_green:focus,
.button_green:hover {
    cursor: pointer;
    color: #417f35;
    background-color: #fff
}

.button_pink {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    width: 235px;
    height: 26px;
    padding-top: 8px;
    transition: all .3s ease-in-out;
    text-align: center;
    text-transform: uppercase;
    color: #d02752;
    border-radius: 16px;
    background-color: #fff
}

.button_pink:focus,
.button_pink:hover {
    cursor: pointer;
    opacity: .85;
    color: #d02752
}

.button_pink_outline {
    color: #fff;
    background-color: #d02752;
    border: .1rem solid #fff;
    padding: .8rem 2rem;
    border-radius: 2.4rem;
    font-weight: 500;
    transition: all .3s
}

.button_pink_outline:focus,
.button_pink_outline:hover {
    color: #d02752;
    background-color: #fff
}

.button_outline {
    display: inline-block;
    font-size: 1.4rem;
    color: #d02752;
    background-color: transparent;
    border: .1rem solid #d02752;
    padding: .5rem 1rem;
    border-radius: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: all .3s
}

.button_outline:focus,
.button_outline:hover {
    color: #fff;
    background-color: #d02752
}

a {
    transition: background-color .2s ease-in,color .2s ease-in,box-shadow .2s ease-in,opacity .2s ease-in,border .2s ease-in;
    text-decoration: none;
    color: #000
}

a:active,
a:focus,
a:hover {
    color: #706d70
}

a.button {
    display: inline-block
}

.hidden,
.masque,
figcaption {
    display: none
}

.desc,
.sstitre .news_content,
.stitre,
.t1,
.t2,
.t3,
.text,
.title,
.titre,
h1,
h2,
h3,
h4,
h5,
h6 .rassurance {
    word-wrap: normal;
    -webkit-hyphens: none;
    hyphens: none;
    -ms-hyphens: none;
    -o-hyphens: none
}

.container {
    width: 100%;
    margin: 0 auto
}

.h1,
h1 {
    font-size: 5rem;
    font-weight: 500;
    line-height: 1.2;
    margin: .5rem 0;
    color: #c93d90;
    text-align: center
}

.h2,
h2 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.2;
    margin: .5rem 0;
    color: #c93d90;
    text-align: center
}

.h3,
h3 {
    font-size: 3.4rem;
    font-weight: 500;
    line-height: 1.2;
    margin: .5rem 0;
    color: #c93d90
}

.body_cms .header-page-classic {
    margin-bottom: 3rem
}

.body_cms .header-page-classic .h3 {
    color: #fff
}

.h4,
.h4 a,
h4,
h4 a {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin: .6em 0;
    text-align: left;
    text-transform: uppercase;
    color: #0ea6af
}

.title_underline::after {
    content: "";
    display: block;
    width: 28rem;
    height: .1rem;
    background-color: #d4dbeb;
    margin: .8rem auto 1.8rem
}

.logo-title {
    display: flex;
    justify-content: center;
    align-items: center
}

.logo-title img {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: .5rem
}

.color1,
.color1 a {
    color: #c93d90
}

.color2,
.color2 a {
    color: #0ea6af
}

#cms {
    line-height: 1.3
}

.cms a {
    transition: background-color .3s ease-in,color .3s ease-in,box-shadow .3s ease-in,padding .3s ease-in,margin .3s ease-in
}

.cms ol,
.mce-content-body ol,
.product .text ol {
    margin-left: 15px;
    list-style-type: decimal
}

.cms ul,
.mce-content-body ul {
    margin-left: 15px;
    list-style-type: inherit;
    list-style-type: square
}

.cms ul li,
.mce-content-body ul li {
    margin: 4px auto
}

.cms img {
    height: auto
}

#cms p {
    line-height: 1.3em;
    margin: .6em auto
}

#cms form .required-label,
#cms form .textarea-type {
    max-width: 103.5rem;
    margin-left: auto;
    margin-right: auto
}

#cms form .required-label {
    margin-top: .5rem
}

#cms form .button_grey {
    font-size: 1.6rem;
    padding-left: 6rem;
    padding-right: 6rem
}

.body_cms #footer {
    margin-top: 3rem
}

#grid_builder.overlay_bg,
.cover_bg {
    position: relative;
    background-position: center center;
    background-size: cover
}

#grid_builder.overlay_bg:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
    background: rgba(255,255,255,.9)
}

.table,
table {
    font-size: 11px;
    border-collapse: collapse;
    color: #3d3d3d;
    border: 1px solid #e3e3e3;
    background-color: #fff
}

.table td,
.table th,
table td,
table th {
    padding: 5px 10px;
    border: 1px solid #e3e3e3
}

.table th,
table th {
    padding: 10px 10px
}

@media screen and (max-width:767px) {
    .h1,
  .titre,
  h1 {
        font-size: 24px
    }

    .h2,
  .stitre,
  h2 {
        font-size: 22px
    }

    .h3,
  .sstitre,
  h3 {
        font-size: 16px
    }

    .h4,
  .ssstitre,
  h4 {
        font-size: 14px
    }
}